/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useEffect } from "react";

import { useAppState } from "@state/state";
import { navigate } from "gatsby";
import { m } from "framer-motion";

const PageContainer = ({
  children,
  className,
  lang,
  translations,
  path,
  id,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ translation }, dispatch] = useAppState();
  const { lang: currentLang = "en" } = translation;

  useEffect(() => {
    if (currentLang && lang !== currentLang && translations) {
      navigate(translations[currentLang]);
    } else {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({ event: "routeChange" });
      dispatch({ type: "setTranslation", lang, translations });
    }
  }, []);

  return (
    <div
      className={`${className} relative flex flex-grow flex-col bg-blue-dark`}
      key={id || path}
    >
      <m.div
        initial={{ y: "-200%", skewY: "-15deg", scale: 1.66 }}
        animate={{ y: "-200%", skewY: "-15deg", scale: 1.66 }}
        exit={{
          y: 0,
          skewY: "-15deg",
          scale: 1.66,
          transition: { duration: 0.5 },
        }}
        className="pointer-events-none fixed inset-0 z-20 transform bg-blue-dark"
      />
      <m.main
        initial={{ scale: 1.05, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        // exit={{ scale: 1.05, opacity: 0 }}
        className="mainContainer z-10 flex-grow origin-[50vw_50vh] bg-white"
      >
        {children}
      </m.main>
    </div>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
