import React from "react";

import { Image, Text } from "@atoms";
import { AppLink } from "@defaults";
import { decodeString, formatPhone, removeNonNumeric } from "@utils";

const CardPerson = ({
  image,
  name,
  fName,
  lName,
  title,
  phone,
  email,
  details,
  inverse,
  altLayout,
  link,
}) => {
  const ixParams = {
    fit: "facearea",
    facepad: 2,
  };
  return (
    <div className="group -mx-1 flex flex-col justify-center text-center leading-tighter">
      <div className="px-1">
        <div className="relative mx-auto w-3/4 justify-center overflow-hidden rounded-full text-center">
          <Image {...image} params={ixParams} aspectratio="1:1" />
        </div>
      </div>
      <div className="p-1">
        <AppLink to={link || null} className={`-mx-1 ${link ? "group" : ""}`}>
          <div
            className={`${
              inverse ? "text-white" : "text-blue"
            } mt-2 text-base font-bold leading-tight`}
          >
            {name || `${fName} ${lName}`}
          </div>
        </AppLink>
        <Text
          variant="xs"
          className={`${inverse ? "text-blue-light" : "text-black"} font-bold`}
        >
          {title}
        </Text>
        {details && phone && (
          <a href={`tel:+1${removeNonNumeric(phone)}`}>
            <Text
              variant="xs"
              className={`${
                inverse ? "text-blue-extralight" : "text-blue-dark"
              } mb-1 mt-2 font-bold hover:text-orange`}
            >
              {formatPhone(removeNonNumeric(phone))}
            </Text>
          </a>
        )}
        {details && email && (
          <a href={`mailto:${decodeString(email)}`}>
            <Text
              variant="xs"
              className={`${
                inverse ? "text-blue-extralight" : "text-blue-dark"
              } font-bold hover:text-orange`}
            >
              {fName && !altLayout ? `Email ${fName}` : "Send a Message"}
            </Text>
          </a>
        )}
        {/* {details && website && (
          <AppLink
            to={website}
            variant="xs"
            className={`${
              inverse ? "text-blue-extralight" : "text-blue-dark"
            } font-bold mt-2 mb-1`}
          >
            Website
          </AppLink>
        )} */}
      </div>
    </div>
  );
};

CardPerson.defaultProps = {
  image: null,
  name: null,
  title: null,
  phone: null,
  email: null,
  details: true,
  fName: null,
  // lName: null,
  inverse: false,
  altLayout: false,
  link: null,
};

export default CardPerson;
