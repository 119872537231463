/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { Image, Text, Icon, RichText } from "@atoms";
import { AppLink } from "@defaults";
import { decodeString, formatPhone, removeNonNumeric } from "@utils";

const PersonCard = ({
  firstName,
  lastName,
  titles,
  departments: _departments,
  bio,
  phone,
  email: _email,
  location,
  website,
  image,
  linkObj: LinkObj = AppLink,
  directoryLink,
}) => {
  const [email, setEmail] = useState("");

  const departments =
    _departments
      ?.filter(d => d.department)
      ?.map(d => d?.department)
      ?.join(", ") || _departments;

  useEffect(() => {
    setEmail(decodeString(_email));
  }, [email, lastName]);

  const ixParams = {
    fit: "facearea",
    facepad: "4",
  };

  return (
    <div className="border-2 border-blue-extralight p-6 sm:p-8 md:p-10">
      <div className="-mx-3 flex flex-wrap items-center sm:-mx-6">
        <div className="relative w-1/3 px-3 sm:px-6 md:w-1/5 lg:w-1/3 lg:pl-10">
          <div className="relative w-full overflow-hidden rounded-full">
            <AppLink to={directoryLink || null}>
              {image && (
                <Image {...image} params={ixParams} aspectratio="1:1" />
              )}
              {!image && (
                <div className="square z-10 flex h-full w-full items-center justify-center border bg-blue-dark transition">
                  <div className="absolute inset-0 -mt-1 flex items-center justify-center text-blue opacity-50">
                    <Icon name="crest" className="h-16 w-16" />
                  </div>
                </div>
              )}
            </AppLink>
          </div>
        </div>
        {/* name + titles */}
        <div className="w-2/3 px-3 md:w-2/5 lg:w-1/3">
          <AppLink to={directoryLink || null}>
            <Text
              variant="body--large"
              className={`font-bold text-blue ${
                directoryLink && directoryLink.length > 1
                  ? "hover:text-orange"
                  : ""
              }`}
            >{`${firstName} ${lastName}`}</Text>
            {titles &&
              titles.length > 0 &&
              titles.map((title, i) => {
                if (title.title) {
                  return (
                    <Text
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      variant="body--small"
                      className="text-blue-dark"
                    >{`${title.title}`}</Text>
                  );
                }
                return null;
              })}
            {typeof departments === "string" && !!departments?.length && (
              <Text variant="body--small" className="mt-1 text-blue-dark">
                {departments}
              </Text>
            )}
          </AppLink>
        </div>

        {/* contact info */}
        <div className="mt-8 w-1/2 px-3 md:mt-0 md:w-2/5 lg:w-1/3 lg:pl-6">
          <div className="-mx-1 flex flex-wrap">
            {/* phone */}
            {phone && (
              <a
                href={`tel:+1${removeNonNumeric(phone)}`}
                className="my-1 mb-2 flex w-1/2 items-center px-1 text-blue hover:text-orange md:w-full"
              >
                <Icon
                  name="phone"
                  className="mr-1 h-6 w-6 flex-shrink-0 text-blue-light"
                />
                <Text variant="mono--extra-small">
                  {formatPhone(removeNonNumeric(phone))}
                </Text>
              </a>
            )}

            {/* location */}
            {location && (
              <div className="my-1 mb-2 flex w-1/2 items-center px-1 md:w-full">
                <Icon
                  name="marker"
                  className="mr-1 h-6 w-6 flex-shrink-0 text-blue-light"
                />
                <Text variant="mono--extra-small" className="text-blue">
                  {location}
                </Text>
              </div>
            )}

            {/* website */}
            {website && website.length > 1 && (
              <LinkObj
                to={website}
                title={`${firstName} ${lastName}'s website`}
                className="my-1 mb-2 flex w-1/2 items-center px-1 text-blue hover:text-orange md:w-full"
              >
                <Icon
                  name="globe"
                  className="mr-1 h-6 w-6 flex-shrink-0 text-blue-light"
                />
                <Text variant="mono--extra-small" className="underline-1 -mt-1">
                  Website
                </Text>
              </LinkObj>
            )}

            {/* email */}
            {email && email.length > 0 && (
              <a
                href={`mailto:${email}`}
                className="my-1 mb-2 flex w-1/2 items-center px-1 text-blue hover:text-orange md:w-full"
              >
                <Icon
                  name="email"
                  className="mr-1 h-6 w-6 flex-shrink-0 text-blue-light"
                />
                <Text variant="mono--extra-small" className="underline-1 -mt-1">
                  {`Email ${firstName}`}
                </Text>
              </a>
            )}
          </div>
        </div>
      </div>

      {bio && bio.length > 1 && (
        <div className="mt-6 sm:mt-12">
          <Text variant="body" className="rich-text">
            <RichText content={bio} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default PersonCard;
