import React from "react";

import { Button, Image, Text, RichText } from "@atoms";
import { AppLink } from "@defaults";
import clsx from "clsx";

const Card = ({
  size,
  tag,
  tagColor,
  image,
  ar,
  label,
  heading,
  copy,
  button,
  secondaryButton,
  link,
  copyColor,
  darkLabel,
  linkObj: LinkObj,
  className: _className,
  inverse,
}) => {
  // determine wrapping element
  // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
  const Wrapper = React.memo(({ children, className: __className }) => {
    if (link) {
      return (
        <LinkObj to={link.link} className={__className}>
          {children}
        </LinkObj>
      );
    }
    return <div className={__className}>{children}</div>;
  });

  // set up vars
  let headingSize;
  let headingColor = "text-blue-dark";
  if (inverse) {
    headingColor = "text-white";
  }
  // determine font size
  switch (size) {
    case "xs":
      headingSize = "body";
      break;
    case "sm":
      headingSize = "body--large";
      break;
    default:
      headingSize = "h6";
      break;
  }
  // return the card
  return (
    <Wrapper className={`relative ${_className}`}>
      <div className="relative">
        {tag && (
          <div className="absolute left-0 top-0 z-10 p-2">
            <div
              className={clsx(
                `px-2 py-1 leading-tight bg-${tagColor} font-sans text-xxs font-bold uppercase tracking-1`,
                {
                  "text-black": tagColor === "pink",
                  "text-white": tagColor !== "pink",
                }
              )}
            >
              {tag}
            </div>
          </div>
        )}
        <div className="relative z-0">
          <Image {...image} aspectratio={ar} />
        </div>
      </div>
      {label && label.length > 1 && (
        <Text
          variant="mono--extra-small"
          className={`${
            darkLabel ? "text-blue-dark" : "text-blue-light"
          } -mb-3 mt-4`}
        >
          {label}
        </Text>
      )}
      <Text variant={headingSize} className={`my-4 font-bold ${headingColor}`}>
        {heading}
      </Text>
      {copy && (!!copy.length || typeof copy === "object") && (
        <Text
          variant={size === "sm" ? "body--small" : "body"}
          className={`my-4 leading-normal text-${
            inverse ? "white" : copyColor
          }`}
        >
          <RichText content={copy} />
        </Text>
      )}
      {(button || secondaryButton) &&
        (button?.link || secondaryButton?.link) && (
          <div className="-mx-1 mt-6 flex flex-wrap">
            {button && (
              <div className="mb-3 w-auto px-2">
                <Button to={link ? null : button.link} size="sm" color="blue">
                  {typeof button === "string" ? button : button.label}
                </Button>
              </div>
            )}
            {!link && secondaryButton && (
              <div className="w-auto px-2">
                <Button
                  to={secondaryButton.link}
                  size="sm"
                  outline
                  color="orange"
                >
                  {secondaryButton.label}
                </Button>
              </div>
            )}
          </div>
        )}
    </Wrapper>
  );
};

Card.defaultProps = {
  tag: null,
  tagColor: "pink",
  ar: "1:1",
  label: null,
  copy: null,
  button: null,
  link: null,
  secondaryButton: null,
  linkObj: AppLink,
  className: null,
  size: "md",
  copyColor: "text-blue-dark",
  inverse: false,
};

export default Card;
