import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const resolvePerson = p => {
  const { firstName, lastName, titles } = p;
  return {
    ...p,
    link: p.path,
    name: `${firstName} ${lastName}`,
    title: titles?.map(({ title }) => title).join(", "),
  };
};

const query = graphql`
  query AllPeopleQuery {
    allPeople {
      nodes {
        id
        path
        blocks {
          attributes {
            settings
          }
        }
        email
        path
        departments {
          department
        }
        firstName
        fullName
        id
        image {
          url
          alt
        }
        website {
          link
        }
        location
        lastName
        phone
        status
        titles {
          title
        }
      }
    }
  }
`;

const dataResolver = ({ allPeople: { nodes: people } }) => {
  return people.map(p => {
    return resolvePerson({
      ...p,
      id: p.id,
      path: p.path,
      // ...JSON.parse(p.blocks[0].attributes.settings),
    });
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  return useMemo(() => dataResolver(response), []);
};

export default useData;
