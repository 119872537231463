/* eslint-disable global-require */
module.exports = {
  content: [
    // main workspace
    `${__dirname}/src/**/*.{js,svg,pcss}`,
  ],
  // important: process.env.IMPORTANT === "yes",
  theme: {
    colors: {
      current: "currentColor",
      transparent: "rgba(255,255,255,0)",
      white: "#ffffff",
      black: "#102730",
      blue: {
        superlight: "#F1F8FD",
        extralight: "#d3e9f4",
        light: "#8BD1FF",
        DEFAULT: "#3861e3",
        alpha: "#3861e34D",
        medium: "#1B3C9B",
        dark: "#0d2d7c",
      },
      pink: { DEFAULT: "#fc5be9", light: "#fc8def" },
      yellow: { DEFAULT: "#fff066", light: "#fff978" },
      orange: { DEFAULT: "#ff5408", light: "#f3864f" },
      peach: "#ffcfc5",
      green: {
        light: "#abf9a0",
      },
    },
    rotate: {
      "-180": "-180deg",
      "-90": "-90deg",
      "-45": "-45deg",
      0: "0",
      45: "45deg",
      90: "90deg",
      135: "135deg",
      180: "180deg",
      270: "270deg",
    },
    fontSize: {
      xxxs: ".55rem",
      xxs: ".67rem",
      xs: ".78rem",
      sm: ".89rem",
      base: "1rem",
      lg: "1.17rem",
      xl: "1.33rem",
      "2xl": "1.5rem",
      "2-5xl": "1.75rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "3.5rem",
      "7xl": "4rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      "11xl": "9rem",
      "12xl": "10rem",
      "h1-mono": "8.89rem",
      h1: "6.22rem",
      h2: "4.44rem",
      h3: "3.11rem",
      h4: "2.67rem",
      h5: "2.23rem",
      h6: "1.78rem",
      body: "1rem",
    },
    screens: {
      xxs: { max: "400px" }, // for super small screens
      // xs is default width, 401-749px
      sm: "700px", // bigger than most phones
      md: "930px",
      lg: "1200px",
      xl: "1900px", // larger than 15" macbook pro
      xxl: "2300px",
    },
    fontFamily: {
      sans: ["cabrillo-sans", "sans-serif"],
      mono: ["cabrillo-mono", "monospace"],
    },
    maxWidth: {
      xs: "15rem",
      sm: "20rem",
      md: "25rem",
      lg: "30rem",
      xl: "35rem",
      "2xl": "40rem",
      "3xl": "50rem",
      "4xl": "60rem",
      "5xl": "70rem",
      "6xl": "80rem",
      "7xl": "90rem",
      "8xl": "100rem",
      "9xl": "110rem",
      "10xl": "120rem",
      full: "100%",
    },
    extend: {
      scale: { "-1": "-1" },
      zIndex: {
        60: "60",
      },
      inset: {
        "1/2": "50%",
      },
      fill: theme => theme("colors"), // do we still need these?
      stroke: theme => theme("colors"), // do we still need these?
      keyframes: {
        "fade-in": {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
      },
      animation: {
        "fade-in": "0.3s fade-in forwards ease",
      },
      opacity: {
        10: ".1",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".9",
        90: ".9",
      },
      maxHeight: {
        "1/2-screen": "50vh",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "faded-heading": "-0.33rem",
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      lineHeight: {
        none: "1",
        tighter: "1.165",
        tight: "1.33",
        normal: "1.67",
        loose: "1.75",
        looser: "1.875",
      },
      height: {
        24: "4rem",
        36: "8rem",
        48: "12rem",
        "1/3-screen": "33vh",
        "1/4-screen": "25vh",
        "1/2-screen": "50vh",
        "5/4": "125%",
        "4/3": "133%",
        "3/2": "150%",
      },
      minHeight: {
        0: "0",
        300: "300px",
        48: "12rem",
        "1/8-screen": `${(1 / 8) * 100}vh`,
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "hero-sm": "22rem",
        "hero-md": "28rem",
        "hero-lg": "34rem",
      },
      padding: {
        28: "7rem",
      },
      width: {
        "1/5": "20%",
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "43%",
        "4/7": "57%",
        "5/7": "72%",
        "6/7": "86%",
        "1/8": "12.5%",
        "4/5": "80%",
      },
    },
  },
};
