import React from "react";
import clsx from "clsx";
import { Container, Image, Icon, RichText } from "@atoms";

const TestimonialSmall = ({ image, quote, name, title, theme = "dark" }) => {
  return (
    <Container variant="xs" className="my-10 font-sans">
      <div
        className={clsx("flex gap-3 p-8 leading-tight", {
          "bg-blue-extralight text-black": theme === "light",
          "bg-blue-dark text-white": theme === "dark",
        })}
      >
        <div className="shield-mask flex size-32 flex-shrink-0 items-center justify-center overflow-hidden bg-blue-light">
          {image && <Image alt="" {...image} fill />}
          {!image && (
            <Icon name="quote" className="size-12 -translate-x-2 text-blue" />
          )}
        </div>
        <blockquote className="flex flex-col gap-3">
          <RichText content={quote} />
          <cite
            className={clsx("flex flex-col gap-1 not-italic", {
              "text-blue-dark": theme === "light",
              "text-blue-extralight": theme === "dark",
            })}
          >
            <p className="font-mono text-sm">{name}</p>
            <p className="text-xs italic">{title}</p>
          </cite>
        </blockquote>
      </div>
    </Container>
  );
};

export default TestimonialSmall;
