import React, { useState, useEffect } from "react";

import { navigate } from "gatsby";
import { SearchSmall, Button } from "@atoms";
import { AppLink } from "@defaults";
import { uid } from "react-uid";
import { useAppState } from "@state/state";

const SecondaryNav = ({
  links,
  linkObject: LinkObj,
  searchObject: SearchObj,
  searchId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [{ translation }, dispatch] = useAppState();
  const { translations, lang } = translation;

  const languageKeys = {
    en: "English",
    es: "Español",
  };

  let otherLang = "es";

  if (lang === "es") {
    otherLang = "en";
  }

  // set current language
  const [language, setLanguage] = useState(languageKeys[lang]);

  // set list of languages
  const [languages, setLanguages] = useState(
    translations
      ? Object.keys(translations)
          .filter(l => translations[l])
          .map(l => languageKeys[l])
      : null
  );

  // set list of languages based on available translations / pageNav
  useEffect(() => {
    if (translations) {
      setLanguages(
        Object.keys(translations)
          .filter(l => translations[l])
          .map(l => languageKeys[l])
      );
      setLanguage(languageKeys[lang]);
    }
  }, [translations]);

  useEffect(() => {
    if (translations) {
      const newLang = Object.keys(languageKeys).find(
        key => languageKeys[key] === language
      );
      if (newLang !== lang && newLang) {
        dispatch({ type: "setLang", lang: newLang });
        navigate(
          translations[newLang] !== "/home/" ? translations[newLang] : "/"
        );
      }
    }
  }, [language]);

  return (
    <div className="relative flex">
      <div className="flex w-full items-center justify-end bg-blue-medium p-2 text-xxs font-bold uppercase tracking-1 text-blue-extralight">
        <div className="hidden items-center lg:flex">
          {links &&
            links.map((link, i) => {
              if (i >= 1) {
                return (
                  <div key={uid(link)} className="px-2 py-1">
                    <LinkObj
                      to={link.link}
                      className="text-white transition hover:text-blue-light"
                    >
                      {link.label}
                    </LinkObj>
                  </div>
                );
              }
              return (
                <div key={uid(link)} className="px-2 py-1">
                  <Button size="xs" color="yellow" to={link.link}>
                    {link.label}
                  </Button>
                </div>
              );
            })}
        </div>
        {languages && languages.length > 1 && (
          <>
            <div className="mx-2 hidden h-full border-l border-blue lg:block" />
            <div className="-m-2 flex items-center font-bold uppercase tracking-1">
              {/* // <DropDown
            //   model={[language, setLanguage]}
            //   placeholder=""
            //   options={languages}
            //   inverse
            // /> */}
              <button
                type="button"
                className="mx-3 block rounded-full bg-blue-extralight px-2 py-1 font-mono text-xxs text-blue transition duration-300 hover:bg-white"
                onClick={() => {
                  setLanguage(languageKeys[otherLang]);
                }}
              >
                {languageKeys[otherLang]}
              </button>
            </div>
          </>
        )}
        <div className="mx-2 h-full border-l border-blue" />
        <div className="flex items-center py-1 pl-2">
          <SearchObj id={searchId} />
        </div>
      </div>
    </div>
  );
};

SecondaryNav.defaultProps = {
  links: null,
  searchObject: SearchSmall,
  linkObject: AppLink,
};

export default SecondaryNav;
