import React from "react";

import draftToHtml from "draftjs-to-html";
import { decode } from "html-entities";

const RichText = ({ content, inverse, unstyled }) => {
  let classes = "";
  if (!unstyled) {
    classes = inverse ? "rich-text rich-text--inverse" : "rich-text";
  }
  // handle old drafthtml content
  if (typeof content !== "string") {
    const markup = draftToHtml(
      typeof content === "string" ? JSON.parse(content) : content
    );
    return (
      <div
        className={classes}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: markup }}
      />
    );
  }
  // otherwise just render the html
  return (
    <div
      className={classes}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: decode(content) }}
    />
  );
};

RichText.defaultProps = {
  content: null,
  inverse: false,
};

export default RichText;
