import React from "react";

import { Container, Text, Button } from "@atoms";
import { Card, Carousel } from "@molecules";

const CardCarousel = ({ heading, subHeading, cards, button, id }) => {
  if (cards?.length) {
    return (
      <div
        id={id}
        className="cabrillo-block relative overflow-hidden bg-blue-extralight py-12"
      >
        <div
          className="absolute left-0 right-0 top-0 z-0 bg-white"
          style={{ bottom: "60%" }}
        />
        <Container padding>
          <div className="relative z-10">
            <Text variant="mono--large-label" className="text-blue">
              {heading}
            </Text>
            <Text variant="h5" className="font-bold text-blue-dark">
              {subHeading}
            </Text>
            <div className="-mx-3 mb-4 mt-8 sm:-mx-6 md:mb-8 md:mt-12">
              <Carousel showMultiple maxVisible={3}>
                {!!cards?.length &&
                  cards.map((card, i) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={i} className="px-3 sm:px-6">
                        <Card {...card} size="sm" ar="8:5" darkLabel />
                      </div>
                    );
                  })}
              </Carousel>
            </div>
            <div>
              {button && button.label.length > 1 && (
                <div className="mb-3 flex items-center justify-center px-2">
                  <Button to={button.link} size="sm" color="blue">
                    {button.label}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

CardCarousel.defaultProps = {
  heading: null,
  subHeading: null,
  cards: null,
  button: null,
};

export default CardCarousel;
