import React, { useRef } from "react";
import clsx from "clsx";
import { useAppState } from "@state/state";
import { Icon } from "@atoms";

// todo: use framer motion + cleanup

const Modal = () => {
  const modalRef = useRef();
  const modalContent = useRef();
  const [{ modal: _modal }, dispatch] = useAppState();
  const { background } = _modal;

  return (
    <div
      ref={modalRef}
      className="pointer-events-none fixed inset-0 z-60 hidden flex-col items-center justify-center opacity-0"
    >
      <button
        type="button"
        className={clsx(
          "absolute inset-0 right-0 z-0 flex items-center p-6 text-xs font-bold uppercase text-transparent",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
      >
        <span style={{ display: "none" }}>close</span>
      </button>
      <button
        type="button"
        className={clsx(
          "group absolute right-0 top-0 z-30 flex items-center p-6 text-xs font-bold uppercase",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
      >
        <span className="sr-only">close</span>
        <Icon
          name="close"
          className={clsx(
            "h-8 w-8 transform text-white transition duration-300 group-hover:text-blue",
            {}
          )}
        />
      </button>
      <button
        type="button"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        className={clsx("absolute inset-0 z-0 opacity-90", background)}
      >
        <span className="sr-only">close</span>
      </button>
      <div
        ref={modalContent}
        className={clsx(
          "z-20 flex h-full w-full items-center justify-center",
          {}
        )}
      />
    </div>
  );
};

Modal.defaultProps = {};

export default Modal;
