import React from "react";
import clsx from "clsx";
import { Container, Image, Text } from "@atoms";

const HeroSimple = ({
  heading,
  subHeading,
  image,
  // breadcrumb,
  color,
  size,
  // primaryButton,
  // secondaryButton,
  icon,
  id,
}) => {
  const backgrounds = {
    "yellow-light": "from-yellow-light/90",
    "pink-light": "from-pink-light/90",
    "orange-light": "from-orange-light/90",
    "blue-light": "from-blue-light/90",
    "green-light": "from-green-light/90",
  };

  return (
    <div
      id={id}
      className="cabrillo-block relative flex min-h-[600px] items-end bg-blue-dark"
    >
      {image && (
        <div className="absolute inset-0 z-0 opacity-100">
          <Image {...image} fill />
        </div>
      )}
      {/* content */}
      <div
        className={clsx(
          "relative z-10 w-full bg-gradient-to-l to-white/80 py-8",
          backgrounds[color] || "from-orange-light/90"
        )}
      >
        <Container className="relative z-10 flex items-center justify-start">
          {/* if subheading */}
          {subHeading && (
            <div className="flex items-center gap-4">
              {icon && (
                <div className="h-20">
                  <Image {...icon} className="h-full w-auto" />
                </div>
              )}
              {heading && (
                <div className="inline-flex max-w-[12rem] text-pretty text-2xl font-bold text-blue-dark">
                  {heading}
                </div>
              )}
              <h1 className={clsx("text-5xl font-bold text-blue-dark")}>
                {subHeading}
              </h1>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

HeroSimple.defaultProps = {
  heading: null,
  subHeading: null,
  image: null,
  // breadcrumb: null,
  color: null,
  size: null,
  primaryButton: null,
  secondaryButton: null,
};

export default HeroSimple;
