import React from "react";

import { Container, Lines, Text, Button } from "@atoms";

const AnnouncementShort = ({ heading, subHeading, button, id }) => {
  return (
    <div
      id={id}
      className="cabrillo-block relative overflow-hidden bg-blue py-12"
    >
      <Container className="relative z-10" padding>
        <div className="w-full lg:w-1/2">
          {heading && (
            <Text
              variant="mono--small-label"
              className="mb-2 text-blue-superlight"
            >
              {heading}
            </Text>
          )}
          {subHeading && (
            <Text
              variant="h5"
              className="my-2 font-bold leading-tight text-white"
            >
              {subHeading}
            </Text>
          )}
          {button && button.label.length > 1 && (
            <div className="mt-6">
              <Button to={button.link} outline color="white">
                {button.label}
              </Button>
            </div>
          )}
        </div>
      </Container>
      <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-1/2 text-blue-extralight opacity-50">
        <Lines fewer />
      </div>
    </div>
  );
};

AnnouncementShort.defaultProps = {
  heading: null,
  subHeading: null,
  button: null,
};

export default AnnouncementShort;
