import React from "react";

import { Container, Text, Button, Icon } from "@atoms";
import { DetailedLink } from "@molecules";

const DetailedLinkList = ({
  heading,
  links,
  button,
  background,
  headerAlignment,
  watermark,
  id,
}) => {
  // background

  let headingColor;
  let buttonColor;

  switch (background) {
    case "peach":
      headingColor = "text-blue-dark";
      buttonColor = "blue";
      break;
    case "blue-extralight":
      headingColor = "text-blue-dark";
      buttonColor = "blue";
      break;
    case "blue":
      headingColor = "text-white";
      buttonColor = "yellow";
      break;
    default: // white
      headingColor = "text-blue";
      buttonColor = "blue";
  }

  return (
    <div
      id={id}
      className={`relative overflow-hidden bg-${background} z-20 ${
        watermark === "show" ? "-mb-0" : ""
      }`}
    >
      {watermark === "show" && (
        <Icon
          name="wordmark"
          className="absolute bottom-0 left-0 right-0 z-0 -m-2 -mb-12 text-white opacity-5 sm:-mb-16"
        />
      )}
      <Container className="relative z-10" padding>
        <div className="py-16">
          {heading && (
            <Text
              variant="h5"
              className={`font-bold text-${headerAlignment} ${headingColor} mb-16 text-blue-dark`}
            >
              {heading}
            </Text>
          )}
          {links && (
            <div className="-mx-3 flex flex-wrap sm:-mx-6">
              {links.map((link, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="mb-12 w-full px-3 sm:w-1/2 sm:px-6">
                    <DetailedLink {...link} inverse={background === "blue"} />
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-4">
            {button && button.label.length > 1 && (
              <div className="mb-3 flex items-center justify-center px-2">
                <Button to={button.link} size="sm" color={buttonColor}>
                  {button.label}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

DetailedLinkList.defaultProps = {
  heading: null,
  links: null,
  button: null,
  background: "white",
  headerAlignment: "center",
  watermark: false,
};

export default DetailedLinkList;
