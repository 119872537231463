import React from "react";
import { m } from "framer-motion";
// todo: use framer motion

import { Container, Button, Text, Icon } from "@atoms";
import { AppLink } from "@defaults";

const MegaMenu = ({ links, open, linkObj: LinkObj, setOpen }) => {
  // the actual menu
  return (
    <m.div
      initial={{ height: 0 }}
      animate={
        !open ? { height: 0 } : { height: "auto", transition: { delay: 0.3 } }
      }
    >
      <Container>
        <div className="relative -mx-3 flex bg-white sm:-mx-6 md:-mx-8 lg:-mx-12">
          <div className="w-1/4 bg-blue-superlight p-12">
            {links && links[0] && (
              <>
                <LinkObj
                  onClick={() => {
                    setOpen(false);
                  }}
                  to={links[0].link}
                  className="mb-10 block font-bold text-blue transition hover:text-blue-light"
                >
                  <Text variant="h5">{links[0].label}</Text>
                </LinkObj>
                {links[0].links &&
                  links[0].links.map((link, i) => {
                    if (i === links[0].links.length - 1) {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={i} className="mt-10">
                          <Button
                            onClick={() => {
                              setOpen(false);
                            }}
                            to={link.link}
                            color="orange"
                            linkObj={LinkObj}
                          >
                            {link.label}
                          </Button>
                        </div>
                      );
                    }
                    return (
                      <LinkObj
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        onClick={() => {
                          setOpen(false);
                        }}
                        to={link.link}
                        className="my-3 block font-bold text-blue-dark transition hover:text-blue-light"
                      >
                        <Text variant="body">{link.label}</Text>
                      </LinkObj>
                    );
                  })}
              </>
            )}
          </div>
          {links && (
            <div className="relative z-10 flex w-3/4 px-3 py-10">
              {links.slice(1, links.length - 1) &&
                links.slice(1, links.length).map((link, i) => {
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      className={`w-1/3 p-6 ${
                        i > 0 ? "border-l border-blue-superlight" : ""
                      }`}
                    >
                      <LinkObj
                        onClick={() => {
                          setOpen(false);
                        }}
                        to={link.link}
                        className="mb-6 block font-mono text-lg font-bold text-blue transition hover:text-blue-dark"
                      >
                        {link.label}
                      </LinkObj>
                      {link.links &&
                        link.links.map((subLink, ii) => {
                          if (!subLink.label) {
                            // eslint-disable-next-line react/no-array-index-key
                            return <div key={ii} className="h-4 w-full" />;
                          }
                          return (
                            <LinkObj
                              // eslint-disable-next-line react/no-array-index-key
                              key={ii}
                              onClick={() => {
                                setOpen(false);
                              }}
                              to={subLink.link}
                              className="my-3 block font-bold text-blue transition hover:text-blue-dark"
                            >
                              <Text variant="body--small">{subLink.label}</Text>
                            </LinkObj>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          )}
          <div className="pointer-events-none absolute bottom-0 right-0 top-0 z-0 flex items-end p-3 sm:p-12 md:p-16 lg:p-20">
            <Icon
              name="crest"
              className="w-sm text-blue-extralight opacity-30"
            />
          </div>
        </div>
      </Container>
    </m.div>
  );
};

MegaMenu.defaultProps = {
  links: null,
  linkObj: AppLink,
  open: false,
  setOpen: null,
};

export default MegaMenu;
