import React, { useEffect } from "react";

import { AppHeader, AppFooter } from "@layout";
import { loadGtm } from "@utils";
import Modal from "@organisms/Modal";
import Userback from "@organisms/Userback";
import { LazyMotion } from "framer-motion";

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
    loadGtm(process.env.GATSBY_GTM_ID);
  }, []);
  return (
    <LazyMotion features={loadFeatures}>
      <div className="flex min-h-screen flex-col justify-between">
        <Modal />
        <AppHeader />
        {children}
        <AppFooter />
        <Userback />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
