export const all = [
  "logo",
  "logoInverse",
  "crest",
  "wordmark",
  "dropdown",
  "search",
  "close",
  "bars",
  "marker",
  "shield",
  "page",
  "globe",
  "shieldSimple",
  "event",
  "parking",
  "video",
];

export const brand = [
  "logo",
  "logoInverse",
  "crest",
  "wordmark",
  "shield",
  "mask",
];

export const ui = [
  "dropdown",
  "close",
  "bars",
  "arrowOrange",
  "arrowBlueLightOnWhite",
];

export const semantic = [
  "marker",
  "search",
  "page",
  "globe",
  "event",
  "phone",
  "book",
  "email",
  "parking",
  "video",
];

// brand
export { default as logo } from "./logo.svg";
export { default as logoInverse } from "./logoInverse.svg";
export { default as crest } from "./crest.svg";
export { default as wordmark } from "./wordmark.svg";
export { default as shield } from "./shield.svg";
export { default as mask } from "./mask.svg";
export { default as shieldSimple } from "./shieldSimple.svg";

// ui
export { default as dropdown } from "./dropdown.svg";
export { default as close } from "./close.svg";
export { default as bars } from "./bars.svg";
export { default as arrowOrange } from "./arrowOrange.svg";
export { default as arrowBlueLightOnWhite } from "./arrowBlueLightOnWhite.svg";
export { default as arrow } from "./arrow.svg";
export { default as quote } from "./quote.svg";

// semantic icons
export { default as marker } from "./marker.svg";
export { default as search } from "./search.svg";
export { default as page } from "./page.svg";
export { default as globe } from "./globe.svg";
export { default as event } from "./event.svg";
export { default as phone } from "./phone.svg";
export { default as book } from "./book.svg";
export { default as email } from "./email.svg";
export { default as parking } from "./parking.svg";
export { default as video } from "./video.svg";

// social
export { default as facebook } from "./facebook.svg";
export { default as pinterest } from "./pinterest.svg";
export { default as instagram } from "./instagram.svg";
export { default as linkedin } from "./linkedin.svg";
export { default as github } from "./github.svg";
export { default as youtube } from "./youtube.svg";
export { default as twitter } from "./twitter.svg";
