import React from "react";

import { Container, Button, Image, Text } from "@atoms";

const HeroSimple = ({
  heading,
  subHeading,
  image,
  // breadcrumb,
  color,
  size,
  primaryButton,
  secondaryButton,
  icon,
  id,
}) => {
  let background;
  let headingColor;
  let subHeadingColor;
  let padding;
  let primaryButtonColor;
  let secondaryButtonColor;
  let imageOffset;
  // handle colors
  switch (color) {
    case "peach":
      background = "bg-peach";
      headingColor = "text-blue";
      subHeadingColor = "text-blue-dark";
      primaryButtonColor = "orange";
      secondaryButtonColor = "white";
      break;
    case "blue-light":
      background = "bg-blue-extralight";
      headingColor = "text-blue";
      subHeadingColor = "text-blue-dark";
      primaryButtonColor = "blue";
      secondaryButtonColor = "blue-dark";
      break;
    case "blue":
      background = "bg-blue";
      headingColor = "text-blue-superlight";
      subHeadingColor = "text-white";
      primaryButtonColor = "yellow";
      secondaryButtonColor = "white";
      break;
    case "blue-extralight":
      background = "bg-blue-extralight";
      headingColor = "text-blue";
      subHeadingColor = "text-blue-dark";
      primaryButtonColor = "blue";
      secondaryButtonColor = "blue-dark";
      break;
    default:
      background = "bg-blue-dark";
      headingColor = "text-blue-light";
      subHeadingColor = "text-white";
      primaryButtonColor = "orange";
      secondaryButtonColor = "white";
      break;
  }
  // handle size
  switch (size) {
    case "sm":
      imageOffset = "-mb-24";
      padding = "py-12";
      break;
    case "lg":
      imageOffset = "-mb-48";
      padding = "py-24";
      break;
    default:
      imageOffset = "-mb-32";
      padding = "py-16";
      break;
  }
  return (
    <div id={id} className={`cabrillo-block ${background} ${padding} relative`}>
      {/* mobile image */}
      {image && (
        <div className="absolute inset-0 z-0 opacity-25 md:hidden">
          <Image {...image} fill />
        </div>
      )}
      {/* content */}
      <Container
        padding
        className="relative z-10 flex items-center justify-start"
      >
        <div className={`w-full ${image ? `pr-6 md:w-3/5` : "max-w-2xl"}`}>
          {/* if heading */}

          {heading && (
            <Text
              variant="mono--large-label"
              className={`font-bold ${headingColor} mb-3`}
            >
              {heading}
            </Text>
          )}

          {/* if subheading */}
          {subHeading && (
            <div className="mb-8 flex items-start gap-4">
              {icon && (
                <div className="size-20 shrink-0">
                  <Image {...icon} className="w-full" />
                </div>
              )}
              <Text
                variant={size === "sm" ? "h3" : "h2"}
                className={`font-bold leading-none ${subHeadingColor} whitespace-pre-wrap`}
              >
                {subHeading}
              </Text>
            </div>
          )}

          {/* buttons */}
          <div className="-mx-2 flex flex-wrap">
            {/* if primaryButton && has label */}
            {!!primaryButton?.label?.length && (
              <div className="mb-3 px-2">
                <Button to={primaryButton.link} color={primaryButtonColor}>
                  {primaryButton.label}
                </Button>
              </div>
            )}

            {/* if secondaryButton && has label */}
            {secondaryButton?.label?.length > 1 && (
              <div className="mb-3 px-2">
                <Button
                  to={secondaryButton.link}
                  color={secondaryButtonColor}
                  outline
                >
                  {secondaryButton.label}
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* desktop image */}
        {image && (
          <div
            className={`hidden w-2/5 md:block lg:${imageOffset} pointer-events-none`}
          >
            <div
              className={`${
                size === "lg" ? "md:-mr-8 lg:-mr-12 xl:-mr-16" : ""
              }`}
            >
              <Image {...image} aspectratio="1:1" />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

HeroSimple.defaultProps = {
  heading: null,
  subHeading: null,
  image: null,
  // breadcrumb: null,
  color: null,
  size: null,
  primaryButton: null,
  secondaryButton: null,
};

export default HeroSimple;
