import React from "react";

import { AccordionSection } from "@atoms";

const Accordion = ({ sections }) => {
  return (
    <>
      {sections.map((section, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <AccordionSection {...section} number={i} />
          </div>
        );
      })}
    </>
  );
};

Accordion.defaultProps = { sections: null };

export default Accordion;
