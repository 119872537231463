import React, { useState, useEffect } from "react";

import { dropdown as Triangle } from "@svg";
import { uid } from "react-uid";
import { m } from "framer-motion";

// ! TODO: Handle motion in wordpress

const Dropdown = ({ model, options, placeholder, inverse, size, blue }) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = model;

  const handleClickOutside = () => {
    if (isActive === true) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    setIsActive(false);
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // eslint-disable-next-line global-require

  let iconSize;

  switch (size) {
    case "xs":
      iconSize = "4";
      break;
    case "sm":
      iconSize = "5";
      break;
    case "md":
      iconSize = "6";
      break;
    case "lg":
      iconSize = "8";
      break;
    default:
      iconSize = "6";
      break;
  }

  let bgColor = "white";
  let textColor = "black";
  let dropDownColor = "black";

  if (blue) {
    bgColor = "blue-extralight";
    textColor = "blue-dark";
    dropDownColor = "blue";
  }

  if (inverse) {
    bgColor = "blue-medium";
    textColor = "blue-extralight";
    dropDownColor = "white";
  }

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setIsActive(s => !s);
        }}
        onBlur={() => setIsActive(false)}
        className={`flex items-center overflow-x-hidden text-${size} bg-${bgColor} text-${textColor} justify-between ${
          size === "xs" ? "px-3 py-1" : "px-4 py-2"
        } w-full ${blue ? "border border-blue-light" : ""}`}
      >
        <span className="whitespace-nowrap text-left">
          {value != null && value !== "" ? value : placeholder}
        </span>
        {/* dropdown Icon */}
        <div
          className={`icon w-${iconSize} h-${iconSize} text-${dropDownColor} `}
        >
          <Triangle />
        </div>
      </button>
      <div className="relative -mt-px">
        <m.ul
          animate={isActive ? { height: "auto" } : { height: 0 }}
          className={`absolute left-0 z-40 text-${size} bg-${bgColor} text-${textColor} ${blue ? "border-b border-l border-r border-blue-light" : ""} right-0 h-0 overflow-x-hidden overflow-y-scroll ${isActive ? "shadow-xl" : "shadow-none"}`}
          style={{ maxHeight: "50vh" }}
        >
          {placeholder.length > 1 && (
            <li className="my-4 px-4">
              <button
                type="button"
                className={`w-full text-${size} underline-2--hover text-left opacity-50`}
                disabled
              >
                {placeholder}
              </button>
            </li>
          )}
          {options.map(option => {
            if (option) {
              return (
                <li key={uid(option?.value || option)} className="my-4 px-4">
                  <button
                    onClick={() => {
                      setValue(option?.value || option);
                    }}
                    type="button"
                    className="underline-2--hover w-full text-left hover:bg-blue-extralight"
                  >
                    {option?.label || option}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </m.ul>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
  blue: false,
};

export default Dropdown;
